import React, { useEffect, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import samplePDF from "./sample.pdf"; // Adjust the path if necessary

// Set the PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`;

const Flipbook1 = () => {
  const [numPages, setNumPages] = useState(null);
  const width = window.innerWidth * 0.9; // 90% of the screen width
  const height = (width * 9) / 16; // Maintain 16:9 aspect ratio

  

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Document file={samplePDF} onLoadSuccess={onLoadSuccess}>
      {numPages && (
        <HTMLFlipBook width={width} height={height}>
          {[...Array(numPages).keys()].map(i => (
            <div key={i + 1}>
              <ReactPdfPage pageNumber={i + 1} width={width} />
            </div>
          ))}
        </HTMLFlipBook>
      )}
    </Document>
  );
};

// Export Flipbook component as default
export default Flipbook1;
