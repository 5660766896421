import React, { useEffect, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import samplePDF from "./sample.pdf";
import './Flipbook.css'; // Import custom CSS

// Set the PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`;

// Page component that renders each PDF page
const Page = React.forwardRef(({ pageNumber }, ref) => {
  return (
    <div ref={ref}>
      <ReactPdfPage pageNumber={pageNumber} />
    </div>
  );
});

const Flipbook = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLandscape, setIsLandscape] = useState(true);

  // Check if the screen is mobile-sized and orientation
  useEffect(() => {
    const checkScreen = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setIsMobile(width <= 768); // Mobile breakpoint
      setIsLandscape(width > height); // Check landscape mode
    };

    checkScreen(); // Check on component mount

    // Listen for window resize or orientation change
    window.addEventListener("resize", checkScreen);
    window.addEventListener("orientationchange", checkScreen);

    return () => {
      window.removeEventListener("resize", checkScreen);
      window.removeEventListener("orientationchange", checkScreen);
    };
  }, []);

  return (
    <>
      {isMobile && !isLandscape ? (
        <div className="rotate-message">Please rotate your device for a better experience.</div>
      ) : (
        <Document file={samplePDF}>
          <HTMLFlipBook
            width={isMobile ? window.innerWidth : "100%"}
            height={isMobile ? window.innerHeight : "100%"}
            className={isMobile ? "mobile-pdf" : "desktop-pdf"}
            singlePage={true} // Always display one page at a time
          >
            {Array.from({ length: 20 }, (_, i) => (
              <Page key={i} pageNumber={i + 1} />
            ))}
          </HTMLFlipBook>
        </Document>
      )}
    </>
  );
};

export default Flipbook;
