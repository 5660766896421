// import logo from './logo.svg';
import './App.css';
// import Flipbook from './component/Flipbook'; // Correct path for Flipbook

import Flipbook1 from './component/Flipbook1';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'; // For annotations if needed
import Flipbook from './component/Flipbook';


function App() {
  return (
    <div className="App">
      {/* <h1>Hello World</h1> */}
      <Flipbook1 />
      {/* <Flipbook/> */}
    </div>
  );
}

export default App;